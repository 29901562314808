import { Currency, formatAmount, formatAmountFromString } from '@epilot/pricing'
import { scroller } from 'react-scroll'

import { ENV_CONFIG } from 'EnvConfig'
import { i18n } from 'i18n/config'

import { EntityItem } from '../api/ApiTypes'
import { EntityAction } from '../context/PortalConfigContext'

import { Locale } from './LanguageUtils'
import { Address } from './OrderUtils'

export enum RequestStatus {
  Active = 'active',
  Draft = 'draft',
  Quote = 'quote',
  Placed = 'placed',
  Cancelled = 'cancelled',
  Complete = 'complete',
  OpenForAcceptance = 'open_for_acceptance'
}

const getQueryParams = (query: string): Record<string, string> => {
  const params = new URLSearchParams(query)

  const queryParams: Record<string, string> = {}

  params.forEach((value, key) => {
    queryParams[key] = value
  })

  return queryParams
}

export const getLocaleDateString = (language: Locale) => {
  switch (language) {
    case Locale.EN:
      return `en-GB`

    case Locale.DE:
      return `de-DE`

    default:
      return `en-GB`
  }
}

export const isEntityHydrated = (entity: EntityItem): boolean => {
  // look up for any relation in the entity
  for (const attribute in entity) {
    if (
      typeof entity[attribute] === 'object' &&
      entity[attribute] !== null &&
      ('$relation_ref' in entity[attribute] || '$relation' in entity[attribute])
    )
      return false
  }

  return true
}

const getEcpDomain = (): string => {
  const pathWithoutQueryData = window.location.href.split('?')[0]

  return window.location.href.includes('localhost')
    ? ENV_CONFIG.DEPLOYED_ECP_URL
    : pathWithoutQueryData
}

const getFiles = (files: any[]): EntityItem[] => {
  if (!files?.length) {
    return []
  }

  return files.filter((file: EntityItem) => file.filename)
}

const toLocalePrice = (
  price: number,
  currency: Currency | string,
  locale = 'de-DE',
  enableSubunitDisplay = false
): string => {
  const currencyCode =
    typeof currency === 'string' ? currency : currency ?? 'EUR'

  return formatAmount({
    amount: price ?? 0,
    currency: currencyCode as Currency,
    format: '$0,0.00',
    locale: locale,
    enableSubunitDisplay
  })
}

const toLocalePriceFromString = (
  price: string,
  currency: Currency | string,
  locale = 'de-DE',
  enableSubunitDisplay = false
): string => {
  const currencyCode =
    typeof currency === 'string' ? currency : currency ?? 'EUR'

  return formatAmountFromString({
    decimalAmount: price ?? '',
    currency: currencyCode as Currency,
    format: '$0,0.00',
    locale: locale,
    enableSubunitDisplay
  })
}

export const formatAddress = (address?: Address): string => {
  const trimmedAddress: string = `${
    address?.additional_info ? `${address?.additional_info}, ` : ''
  }${address?.street || ''} ${address?.street_number || ''}, ${
    address?.postal_code || ''
  } ${address?.city || ''} ${address?.country || ''}`.trim()

  if (trimmedAddress === ',') {
    return ''
  }

  return trimmedAddress
}

const toLocaleDateString = (dateObject: Date): string => {
  const locale = i18n.language

  const localeDate = dateObject.toLocaleDateString(
    getLocaleDateString(i18n.language as Locale),
    {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
  )

  if (locale === 'de-DE') {
    const [day, month, year] = localeDate.split('.')

    return `${day}.${month}.${year}`
  }

  if (locale === 'en') {
    const [day, month, year] = localeDate.split('/')

    return `${day}.${month}.${year}`
  }

  return localeDate
}

const toLocaleDate = (date: string | null, default_value?: string): string => {
  if (!date) {
    return default_value || '-'
  }

  const dateObject = new Date(date)

  return toLocaleDateString(dateObject)
}

const toLocaleDateAndTime = (date: string): string => {
  const dateObject = new Date(date)

  return dateObject.toLocaleTimeString(
    getLocaleDateString(i18n.language as Locale),
    {
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }
  )
}

const isPlaceholderDate = (date: string) => {
  if (typeof date !== 'string') return false

  // There are the known placeholders our customers use
  const PLACEHOLDERS = ['0000-00-00', '1970-01-01', '2099-12-31', '9999-12-31']

  return PLACEHOLDERS.some((placeholder) => date.startsWith(placeholder))
}

const getActionsForEntity = (
  actions: EntityAction[],
  entity: string
): EntityAction[] => {
  return actions?.filter((action) => action.slug === entity)
}

const scrollToRow = (id: string, containerId: string): void => {
  scroller.scrollTo(id, {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
    containerId,
    offset: -10
  })
}

const wait = (time = 1000) =>
  new Promise((resolve) => setTimeout(resolve, time))

/**
 * Ensure URL contains a protocol with a selected fallback
 */
const ensureUrlProtocol = (url?: string | null, fallback = '//') => {
  if (!url || typeof url !== 'string') return url

  return url.match(/^(\w+:\/\/|\/\/)/) ? url : `${fallback}${url}`
}

const formatNumber = (
  input: number,
  options?: { thousandsSeparator: boolean }
) => {
  const { thousandsSeparator = true } = options || {}

  // Convert number to string and split into integer and decimal parts
  const [integerPart, decimalPart] = Number(input).toFixed(2).split('.')

  // Add thousand separators to the integer part
  const formattedIntegerPart = thousandsSeparator
    ? integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    : integerPart

  // If there's a decimal part, concatenate it with the appropriate format
  if (decimalPart) {
    return formattedIntegerPart + ',' + decimalPart.padEnd(2, '0')
  } else {
    return formattedIntegerPart + ',00'
  }
}

/**
 * Limit the execution of the functionality to once per delay period.
 *
 * Compared to the common throttle, it works even if the user re-opens the page.
 * As such, it requires an identifier and is more useful with large delays like a minute.
 *
 * @param id Identifier of the functionality.
 * @param fn Function to throttle.
 * @param delaySeconds Delay in seconds.
 */
const throttleAcrossVisits =
  <T extends unknown[]>(
    id: string,
    fn: (...args: T) => void,
    delaySeconds: number
  ) =>
  (...args: T) => {
    const lastCall =
      Number(window.localStorage.getItem(`throttleAcrossVisits:${id}`)) || 0

    const now = Date.now()

    if (now - lastCall >= delaySeconds * 1000) {
      fn(...args)

      window.localStorage.setItem(`throttleAcrossVisits:${id}`, now.toString())
    }
  }

const isJsonEmpty = (jsonObject) => {
  return Object.keys(jsonObject).length === 0
}

export default {
  wait,
  getEcpDomain,
  getFiles,
  toLocalePrice,
  toLocalePriceFromString,
  toLocaleDate,
  toLocaleDateString,
  toLocaleDateAndTime,
  isPlaceholderDate,
  getActionsForEntity,
  scrollToRow,
  ensureUrlProtocol,
  getQueryParams,
  formatNumber,
  throttleAcrossVisits,
  isJsonEmpty
}

export const VALIDITY_SORT_ORDER: Record<string, number> = {
  current: 1,
  future: 2,
  past: 3,
  unknown: 4
}

export function getTimeSliceValidity(
  start: string,
  end: string,
  date: string
): 'past' | 'future' | 'current' {
  if (start && end) {
    if (date >= start && date <= end) {
      return 'current'
    }
    if (date > end) {
      return 'past'
    }

    return 'future'
  }

  // start only
  if (start && !end) {
    if (date >= start) {
      return 'current'
    }

    return 'future'
  }

  // end only
  if (date > end) {
    return 'past'
  }

  return 'current'
}
