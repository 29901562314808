const rootRoute = () => {
  return `/`
}

const loginRoute = (lastPath?: string) => {
  if (lastPath) {
    return `/login?lastPath=${lastPath}`
  }

  return `/login`
}

const registrationRoute = () => {
  return `/register`
}

const registerActivationRoute = (email?: string) => {
  if (email) {
    return `/register/activation?email=${email}`
  }

  return '/register/activation'
}

const registerSuccessRoute = () => {
  return '/register/success'
}

const myAccountRoute = () => {
  return `/my-account`
}

const dashboardRoute = () => {
  return `/dashboard`
}

const contractsRoute = () => {
  return `/contracts`
}

const paymentsRoute = () => {
  return `/payments`
}

const contractRoute = (id: string) => {
  return `/contracts/${id}`
}

const documentsRoute = () => {
  return `/documents`
}

const requestsRoute = () => {
  return `/requests`
}

const ordersRoute = () => {
  return `/requests/orders`
}

const orderRoute = (id: string) => {
  return `/requests/orders/${id}`
}

const opportunitiesRoute = () => {
  return `/requests/opportunities`
}

const opportunityRoute = (id: string) => {
  return `/requests/opportunities/${id}`
}

const forgotPasswordRoute = () => {
  return `/forgot-password`
}

const forgotPasswordChangeRoute = () => {
  return `/forgot-password/change`
}

const setPasswordForNewEmailRoute = () => {
  return `/set-password/new-email`
}

const confirmEmailRoute = () => {
  return `/confirm-email`
}

const pendingAccountRoute = () => {
  return `/pending-account`
}

const metersRoute = () => {
  return '/meters'
}

const meterRoute = (id: string) => {
  return `/meters/${id}`
}

const supportRoute = () => {
  return `/support`
}

const myAccountAccountRoute = () => {
  return `/my-account/account`
}

const myAccountSettingsRoute = () => {
  return `/my-account/settings`
}

export default {
  rootRoute,
  loginRoute,
  registrationRoute,
  registerActivationRoute,
  registerSuccessRoute,
  dashboardRoute,
  paymentsRoute,
  myAccountRoute,
  contractsRoute,
  contractRoute,
  documentsRoute,
  requestsRoute,
  ordersRoute,
  orderRoute,
  opportunitiesRoute,
  opportunityRoute,
  forgotPasswordRoute,
  forgotPasswordChangeRoute,
  setPasswordForNewEmailRoute,
  confirmEmailRoute,
  pendingAccountRoute,
  metersRoute,
  meterRoute,
  supportRoute,
  myAccountAccountRoute,
  myAccountSettingsRoute
}
