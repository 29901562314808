export const CUSTOMER_QUERY_IDS = {
  // Portal Config
  GET_PORTAL_CONFIG: 'CUSTOMER_GET_CONFIG',
  GET_EXTERNAL_LINKS: 'GET_EXTERNAL_LINKS',

  // Portal Wigets
  GET_PORTAL_WIDGETS: 'GET_PORTAL_WIDGETS',
  GET_PUBLIC_PORTAL_WIDGETS: 'GET_PUBLIC_PORTAL_WIDGETS',

  // Authentication
  DOES_USER_EXIST_NEW: 'DOES_USER_EXIST_NEW',
  CHECK_EMAIL_FOR_CONTACT: 'CHECK_EMAIL_FOR_CONTACT',
  DOES_CUSTOMER_ID_EXIST: 'DOES_CUSTOMER_ID_EXIST',

  // Registration
  CHECK_MATCHING_CONTACT: 'CHECK_MATCHING_CONTACT',

  // Contact
  GET_CONTACT: 'GET_CONTACT',

  // Contact
  PORTAL_USER: 'PORTAL_USER',

  // Order
  GET_ALL_ORDERS: 'GET_ALL_ORDERS',
  GET_ORDER_DETAILS: 'GET_ORDER_DETAILS',

  // Opportunity
  GET_ALL_OPPORTUNITIES: 'GET_ALL_OPPORTUNITIES',
  GET_OPPORTUNITY_DETAILS: 'GET_OPPORTUNITY_DETAILS',
  SEARCH_OPPORTUNITIES: 'SEARCH_OPPORTUNITIES',
  GET_SEARCHEABLE_ATTRIBUTES: 'GET_SEARCHEABLE_ATTRIBUTES',

  // Contract
  GET_CONTRACTS: 'GET_CONTRACTS',
  GET_CONTRACT_DETAILS: 'GET_CONTRACT_DETAILS',

  // Documents
  GET_DOCUMENTS: 'GET_DOCUMENTS',
  GET_DOCUMENTS_COUNT_BY_ENTITY: 'GET_DOCUMENTS_COUNT_BY_ENTITY',

  // Billing Events
  GET_BILLING_EVENTS: 'GET_BILLING_EVENTS',
  COUNT_BILLING_EVENTS: 'COUNT_BILLING_EVENTS',

  // Balance
  GET_BALANCE: 'GET_BALANCE',

  // Schemas
  GET_SCHEMAS: 'GET_SCHEMAS',

  // Organization
  GET_ORGANIZATION_SETTINGS: 'GET_ORGANIZATION_SETTINGS',

  // Identifiers
  GET_ENTITIES_BY_IDENTIFIERS: 'GET_ENTITIES_BY_IDENTIFIERS',

  // Meters
  GET_METER_DETAILS: 'GET_METER_DETAILS',
  GET_METERS: 'GET_METERS',
  GET_METER_COUNTERS: 'GET_METER_COUNTERS',
  GET_COUNTER_DETAILS: 'GET_COUNTER_DETAILS',
  GET_READINGS_BY_INTERVAL: 'GET_READINGS_BY_INTERVAL',

  // Endpoint id to get the list of all activities for an entity entities
  GET_ACTIVITY_FEED: 'GET_ACTIVITY_FEED',

  // Validate the cadence rule in API
  VALIDATE_CADENCE_RULE: 'VALIDATE_CADENCE_RULE',

  // search entities by payment id
  SEARCH_ENTITIES_BY_PAYMENT: 'SEARCH_ENTITIES_BY_PAYMENT',

  // get public part of the extensions
  GET_PUBLIC_EXTENSIONS: 'GET_PUBLIC_EXTENSIONS',
  // get consumption data from extension
  GET_CONSUMPTIONS: 'GET_CONSUMPTIONS',
  GET_COSTS: 'GET_COSTS',
  GET_PRICES: 'GET_PRICES'
}
