import process from 'process/browser'
import ReactDOM from 'react-dom'

import CustomerApiClient from './api/CustomerApiClient'
import CommonUtils from './utils/CommonUtils'

const CANARY_BUNDLE = '/bundle.js'

Object.assign(globalThis, { process })

export const main = async () => {
  import('./Root').then(({ default: Root }) => {
    ReactDOM.render(<Root />, document.getElementById('root'))
  })

  // if not, check if we are in stable mode
  const portalConfig = await CustomerApiClient.getPortalConfigByDomain(
    CommonUtils.getEcpDomain()
  ).then((res) => res.data)

  const isCanary = portalConfig?.org_settings?.['canary']?.enabled

  const isOverridden =
    window['importMapOverrides'].getOverrideMap().imports['ecp'] ===
    CANARY_BUNDLE

  if (isCanary && !isOverridden) {
    window['importMapOverrides'].addOverride('ecp', CANARY_BUNDLE)
    window['importMapOverrides'].enableOverride('ecp')

    window.location.reload()
  }

  if (!isCanary && isOverridden) {
    window['importMapOverrides'].removeOverride('ecp')
    window['importMapOverrides'].disableOverride('ecp')

    window.location.reload()
  }

  // Respect no search engine indexing
  if (portalConfig.prevent_search_engine_indexing) {
    let metaRobots = document.head.querySelector('meta[name="robots"]')

    if (!metaRobots) {
      metaRobots = document.createElement('meta')
      metaRobots.setAttribute('name', 'robots')
      document.head.appendChild(metaRobots)
    }

    metaRobots.setAttribute('content', 'noindex, nofollow')
  }

  const referrer = document.referrer

  // Store it in localStorage if it’s not already set (to avoid overwriting during login page refresh)
  // And add it to analytics session when user logs in
  if (!localStorage.getItem('referrer') && referrer) {
    localStorage.setItem('referrer', referrer)
  }
}

main()
